<template>
  <div class="profitRateResultList">
    <el-row class="profitTitle">
      <el-col :span="12">
        <div class="titleLeft">
          <div class="days">
            连续警告天数：<span>{{ productReport.lossDays }}</span>
          </div>
          <div class="productResult">
            经营评估：
            <img
              :src="imgSrc[productReport.profitRateResult]"
              alt=""
            />
          </div>
        </div>
      </el-col>
      <el-col
        :span="12"
        class="date"
      >
        {{ dateRange[0] }} ~
        {{ dateRange[1] }}
      </el-col>
    </el-row>
    <el-row class="cardList">
      <el-col :span="4">
        <div class="grid-content bg-purple-light">毛利率</div>
        <div class="grid-content">
          <!-- <el-tooltip
              :content="valCarryPercent('grossMarginRate')"
              placement="top"
              effect="dark"
            >
              <span>{{ valCarryPercent("grossMarginRate") }}</span>
            </el-tooltip> -->
          <span>{{ valCarryPercent('grossMarginRate') }}</span>
        </div>
      </el-col>
      <el-col :span="4">
        <div class="grid-content bg-purple-light">ROI</div>
        <div class="grid-content">
          <!-- <el-tooltip
              :content="decimalFix('roi',2)"
              placement="top"
              effect="dark"
            >
              <span>{{ decimalFix("roi",2) }}</span>
            </el-tooltip> -->
          <span>{{ decimalFix('roi', 2) }}</span>
        </div>
      </el-col>
      <el-col :span="4">
        <div class="grid-content bg-purple-light">ROI临界值</div>
        <div class="grid-content">
          <!-- <el-tooltip
              :content="decimalFix('lowestRoi',2)"
              placement="top"
              effect="dark"
            >
              <span>{{ decimalFix("lowestRoi",2) }}</span>
            </el-tooltip> -->
          <span>{{ decimalFix('lowestRoi', 2) }}</span>
        </div>
      </el-col>
      <el-col :span="4">
        <div class="grid-content bg-purple-light">平均广告费</div>
        <div class="grid-content">￥{{ decimalFix('avgAdCost', 2) }}/{{ productReport.currencySign }}{{ productReport.originAvgAdCost }} </div>
      </el-col>
      <el-col :span="4">
        <div class="grid-content bg-purple-light">广告临界值</div>
        <div class="grid-content">￥{{ decimalFix('adCriticalVal', 2) }} /{{ productReport.currencySign }}  {{ productReport.originAdCriticalVal }}</div>
      </el-col>
      <!-- </el-row>
                      <el-row> -->
      <el-col :span="4">
        <div class="grid-content bg-purple-light">签收率</div>
        <div class="grid-content">{{ valCarryPercent('signRate') }}</div>
      </el-col>
      <el-col :span="4">
        <div class="grid-content bg-purple-light">订单量</div>
        <div class="grid-content">
          {{ formatV1('orderCount') }}
        </div>
      </el-col>
      <el-col :span="4">
        <div class="grid-content bg-purple-light">客单量</div>
        <!-- <div class="grid-content">{{ formatV1("roi") }}</div> -->
        <div class="grid-content">{{ formatV1('countPerCustomer') }}</div>
      </el-col>
      <el-col :span="4">
        <div class="grid-content bg-purple-light">客单价</div>
        <!-- <div class="grid-content">{{ formatV1("lowestRoi") }}</div> -->
        <div class="grid-content">￥{{ decimalFix('amountPerCustomer', 2) }}</div>
      </el-col>

      <el-col :span="4">
        <div class="grid-content bg-purple-light">广告费占比</div>
        <div class="grid-content">
          <!-- {{ valCarryPercent("adRate") }} -->
          {{ valCarryPercent('adRate') }}
        </div>
      </el-col>

      <el-col :span="4">
        <div class="grid-content bg-purple-light">采购占比</div>
        <div class="grid-content">
          <!-- {{ valCarryPercent("buyRate") }} -->
          {{ valCarryPercent('buyRate') }}
        </div>
      </el-col>
      <el-col :span="4">
        <div class="grid-content bg-purple-light">运费占比</div>
        <div class="grid-content">
          <!-- {{ valCarryPercent("logisticsRate") }} -->
          {{ valCarryPercent('logisticsRate') }}
        </div>
      </el-col>
      <!-- </el-row>
                      <el-row> -->
      <el-col :span="4">
        <div class="grid-content bg-purple-light">
          <el-tooltip
            content="三项成本合计"
            placement="top"
            effect="dark"
          >
            <span>三项成本合计</span>
          </el-tooltip>
        </div>
        <div class="grid-content">
          {{ valCarryPercent('threeRate') }}
        </div>
      </el-col>
      <el-col :span="4">
        <div class="grid-content bg-purple-light">
          <el-tooltip
            content="其他(含退款/经营成本)"
            placement="top"
            effect="dark"
          >
            <span>其他(含退款/经营成本)</span>
          </el-tooltip>
        </div>
        <div class="grid-content">{{ valCarryPercent('otherRate') }}</div>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import HIGHT from '@/assets/icon/level1.png';
import MID from '@/assets/icon/level2.png';
import LOW from '@/assets/icon/level3.png';
import LOW2 from '@/assets/icon/level4.png';
export default {
  name: 'productReportCard',
  props: {
    productReport: {
      type: Object,
      default: () => {},
    },
    platform:{
      type:String,
      default:'facebook'
    },
    // dateRange: {
    //   type: Object,
    //   default: () => {
    //     let dateRange = { ...JSON.parse(localStorage.getItem('adDateRange')) }.dateRange;
    //     return {
    //       dateRange: dateRange || [],
    //     };
    //   },
    // },
  },
  data() {
    return {
      imgSrc: {
        HIGHT,
        MID,
        LOW,
        LOW2,
      },
      dateRange:this.platform == 'facebook'?({ ...JSON.parse(localStorage.getItem('adDateRange')) }.dateRange || []):({ ...JSON.parse(localStorage.getItem('batchAdDateRange')) }.dateRange || [])
    };
  },
  created() {},
  computed: {
    formatV1() {
      return function (key) {
        return this.productReport[key] || '--';
      };
    },
    valNameOrId() {
      return function (key) {
        return this.productReport[`${key}Name`] || this.productReport[`${key}Id`] || '--';
      };
    },
    formatTip() {
      return function (key) {
        return this.productReport[`${key}Name`] || String(this.productReport[`${key}Id`]) || '--';
      };
    },
    valCarryPercent() {
      return function (key) {
        return this.productReport[key] ? this.productReport[key].toFixed(2) + '%' : '--';
      };
    },
    decimalFix() {
      return function (key, n) {
        let val = this.productReport[key];
        return val ? parseFloat(val).toFixed(n) : 0;
      };
    },
  },
};
</script>

<style lang="scss" scoped>
.cardList {
  .el-col-4 {
    width: 20%;
  }
}
.profitRateResultList {
  .profitTitle {
    padding: 10px;
    .days {
      margin-right: 20px;
      span {
        font-size: 18px;
        color: #ff0000;
      }
    }
    .date {
      text-align: right;
    }
  }
  .titleLeft {
    display: flex;
    align-items: center;
  }
  .el-row {
    border: none;
  }
  .bg-purple-light {
    background: #efefef;
  }
  .grid-content {
    line-height: 40px;
    padding: 0 5px;
    border: none;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    font-size: 12px;
    &.lh {
      line-height: 20px;
      overflow: inherit;
      text-overflow: visible;
      white-space: pre-wrap;
    }
    // &:hover{
    //     text-overflow: visible;
    //     // animation: 3s siderbar linear infinite normal;
    //     overflow:inherit;
    // }
    @keyframes siderbar {
      0% {
        transform: translateX(0);
        -webkit-transform: translateX(0);
      }
      100% {
        transform: translateX(-45px);
        -webkit-transform: translateX(-45px);
      }
    }
  }
  img {
    width: 20px;
    height: 20px;
    vertical-align: middle;
  }
}
.profitRateResult {
  img {
    width: 30px;
    height: 30px;
  }
}
</style>
